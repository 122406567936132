import React, { useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment-timezone';
import CardHeroCard from '@components/cardheroCard';
import LoadingBackdrop from '@components/loadingBackdrop';
import SuccessMessageDialog from '@components/successMessageDialog';
import ErrorMessageDialog from '@components/errorMessageDialog';
import ConfirmationStatus from '@components/confirmationUserDialog';
import { apiRequestStatus } from '@data/constants';
import { customization } from '@customization/default';
import { InputAdornment } from '@material-ui/core';
import * as userActions from '@redux/user/userActions';
import * as cardActions from '@redux/card/cardActions';
import {
  putCardBlock as putCardBlockService,
  putCardUnblock as putCardUnblockService,
  putCardCancel as putCardCancelService,
  putCardLostStolen as putCardLostStolenService,
  putCardReplace as putCardReplaceService,
  patchAccountLimit as patchAccountLimitService,
} from '@services/api/cards';
import history from '@utils/history';
import { transformViewEditCard, transformAccountLimitCard } from '@utils/transformation';
import { cardStatus } from '@data/constants';
import CardDetailLoading from './CardDetailLoading';
import UpdateAddressDialog from './UpdateAddress';
import { addLocationDialogStyle } from './style';

function CardDetailDialog(props) {
  const [cardDetail, setCardDetail] = useState({});
  const [cashWithdrawalLimit, setCashWithdrawalLimit] = useState({
    value: null,
    validation: false,
  });
  const [transactionLimit, setTransactionLimit] = useState({
    value: null,
    validation: false,
  });
  const [dailySpendLimit, setDailySpendLimit] = useState({
    value: null,
    validation: false,
  });

  const { classes, openCardDetailDialog, bindCloseCardDetail, loading } = props;

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openUpdateAddress, setOpenUpdateAddress] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [cardDetailLoading, setCardDetailLoading] = useState(apiRequestStatus.IDLE);
  const [blockCardConfirmationDialog, setBlockCardConfirmationDialog] = useState(false);
  const [unblockCardConfirmationDialog, setUnblockCardConfirmationDialog] =
    useState(false);
  const [isBlockUnblockCard, setIsBlockUnblockCard] = useState(false);

  React.useEffect(() => {
    const mappingCardDetail = () => {
      if (Object.keys(props.card).length !== 0) {
        const mappedCardDetail = transformViewEditCard(props.card);
        setCardDetail(mappedCardDetail.mappedCardDetailToState);
        setCashWithdrawalLimit({
          ...cashWithdrawalLimit,
          value: mappedCardDetail.mappedTransactionToState.cashWithdrawalLimit,
        });
        setTransactionLimit({
          ...transactionLimit,
          value: mappedCardDetail.mappedTransactionToState.transactionLimit,
        });
        setDailySpendLimit({
          ...dailySpendLimit,
          value: mappedCardDetail.mappedTransactionToState.dailyLimit,
        });
      }
    };
    mappingCardDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.card]);

  const onChangeInput = (value, eventName) => {
    if (eventName === 'cashWithdrawalLimit') {
      setCashWithdrawalLimit({ ...cashWithdrawalLimit, value });
    } else if (eventName === 'transactionLimit') {
      setTransactionLimit({ ...transactionLimit, value });
    } else if (eventName === 'dailySpendLimit') {
      setDailySpendLimit({ ...dailySpendLimit, value });
    }
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    const regex = /^[0-9.]+$/;
    if (value.match(regex) !== null) {
      onChangeInput(value, name);
    }
  };

  const handleReplaceCard = async (form) => {
    setOpenUpdateAddress(false);
    setCardDetailLoading(apiRequestStatus.PENDING);

    try {
      const response = await putCardReplaceService(props.token, {
        card_id: cardDetail.cardId,
        address_1: form.address1.value,
        address_2: form.address2.value,
        suburb: form.suburb.value,
        post_code: form.postalCode.value,
        state_fk: form.stateId.value,
      });

      if (response.status === 200) {
        setCardDetailLoading(apiRequestStatus.RESOLVED);
        setOpenSuccess(true);
        setSuccessMessage(customization.cardDetails.successMessage.replaceCard);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setCardDetailLoading(apiRequestStatus.REJECTED);
      setErrorMessage({
        isError: true,
        message: error?.response?.data?.message || error.message,
      });
    }
  };

  const parsingValue = (e) => {
    let value = e.target.value;
    if (value === '') {
      value = 'N/A';
    }
    if (value !== 'N/A') {
      const toNumber = parseFloat(value);
      const parseVal = toNumber.toFixed(cardDetail.lastDecimalPlace);
      onChangeInput(parseVal.toString(), e.target.name);
    } else {
      onChangeInput(value, e.target.name);
    }
  };

  const handleDelete = (e) => {
    const { value, name } = e.target;
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (value.length === 1 || value === 'N/A') {
        onChangeInput('', name);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setCardDetailLoading(apiRequestStatus.PENDING);
    try {
      const cardAccountLimitTransformed = transformAccountLimitCard({
        cashWithdrawalLimit:
          cashWithdrawalLimit.value === 'N/A' ? null : cashWithdrawalLimit.value,
        transactionLimit:
          transactionLimit.value === 'N/A' ? null : transactionLimit.value,
        dailySpendLimit: dailySpendLimit.value === 'N/A' ? null : dailySpendLimit.value,
        accountId: cardDetail.accountId,
        cardId: cardDetail.cardId,
      });
      const response = await patchAccountLimitService(
        props.token,
        cardAccountLimitTransformed,
      );
      if (response.status === 200) {
        setCardDetailLoading(apiRequestStatus.RESOLVED);
        setOpenSuccess(true);
        setSuccessMessage(customization.cardDetails.successMessage.saveChanges);
      }
    } catch (error) {
      setCardDetailLoading(apiRequestStatus.REJECTED);
      setErrorMessage({
        isError: true,
        message: error?.response?.data?.message || error.message,
      });
    }
  };

  const handleBlockCard = async (e) => {
    e.preventDefault();

    setBlockCardConfirmationDialog(true);
    setIsBlockUnblockCard(true);
  };

  const handleConfirmationBlockCard = async (e, action) => {
    e.preventDefault();

    if (!action) {
      return setBlockCardConfirmationDialog(false);
    }

    setBlockCardConfirmationDialog(false);
    try {
      setCardDetailLoading(apiRequestStatus.PENDING);
      const response = await putCardBlockService(props.token, {
        card_id: cardDetail.cardId,
      });
      if (response.status === 200) {
        setCardDetailLoading(apiRequestStatus.RESOLVED);
        setOpenSuccess(true);
        setSuccessMessage(customization.cardDetails.successMessage.blockCard);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setCardDetailLoading(apiRequestStatus.REJECTED);
      setBlockCardConfirmationDialog(false);
      setErrorMessage({
        isError: true,
        message: error?.response?.data?.message || error.message,
      });
    }
  };

  const handleUnblockCard = async (e) => {
    e.preventDefault();

    setUnblockCardConfirmationDialog(true);
    setIsBlockUnblockCard(true);
  };

  const handleUnblockConfirmationCard = async (e, action) => {
    e.preventDefault();

    if (!action) {
      return setUnblockCardConfirmationDialog(false);
    }

    setUnblockCardConfirmationDialog(false);
    try {
      setCardDetailLoading(apiRequestStatus.PENDING);
      const response = await putCardUnblockService(props.token, {
        card_id: cardDetail.cardId,
      });
      if (response.status === 200) {
        setCardDetailLoading(apiRequestStatus.RESOLVED);
        setOpenSuccess(true);
        setSuccessMessage(customization.cardDetails.successMessage.unblockCard);
        setIsBlockUnblockCard(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setCardDetailLoading(apiRequestStatus.REJECTED);
      setUnblockCardConfirmationDialog(false);
      setErrorMessage({
        isError: true,
        message: error?.response?.data?.message || error.message,
      });
    }
  };

  const handleReportCardStolen = async (e) => {
    e.preventDefault();
    setCardDetailLoading(apiRequestStatus.PENDING);
    try {
      const response = await putCardLostStolenService(props.token, {
        card_id: cardDetail.cardId,
      });
      if (response.status === 200) {
        setCardDetailLoading(apiRequestStatus.RESOLVED);
        setOpenSuccess(true);
        setSuccessMessage(customization.cardDetails.successMessage.replaceCard);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setCardDetailLoading(apiRequestStatus.REJECTED);
      setErrorMessage({
        isError: true,
        message: error?.response?.data?.message || error.message,
      });
    }
  };

  const handleCancelCard = async (e) => {
    e.preventDefault();
    setCardDetailLoading(apiRequestStatus.PENDING);
    try {
      const response = await putCardCancelService(props.token, {
        card_id: cardDetail.cardId,
      });
      if (response.status === 200) {
        setCardDetailLoading(apiRequestStatus.RESOLVED);
        setOpenSuccess(true);
        setSuccessMessage(customization.cardDetails.successMessage.cancelCard);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setCardDetailLoading(apiRequestStatus.REJECTED);
      setErrorMessage({
        isError: true,
        message: error?.response?.data?.message || error.message,
      });
    }
  };

  const handleRedirectToViewCards = () => {
    const page = 0;
    const rowsPerPage = 20;
    const searchBy = 'user_id';
    const userId = cardDetail.userId;
    const userStatus = '';
    props.userAction
      .getLinkedUser(
        props.token,
        page + 1,
        rowsPerPage,
        searchBy,
        userStatus,
        userId.toString(),
      )
      .then(() => {
        if (props.error.statusCode) {
          setErrorMessage({
            isError: true,
            message: `Get card list error: ${props.error.statusCode}`,
          });
        } else {
          history.push('/admin/dashboard/view-users');
        }
      });
  };

  const handleClose = () => {
    bindCloseCardDetail();
  };

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
    if (isBlockUnblockCard) {
      await props.cardActions.getCardDetailById(props.token, cardDetail.cardId);

      if (props?.error?.statusCode) {
        setErrorMessage({
          isError: true,
          message: `Get card list error: ${props.error.statusCode}`,
        });
      } else {
        setOpenSuccess(false);
        setBlockCardConfirmationDialog(false);
        setUnblockCardConfirmationDialog(false);
        setIsBlockUnblockCard(false);

        await props.cardActions.cardClearErrorState();
      }
    } else {
      bindCloseCardDetail();
    }
  };

  const handleCloseErrorMessage = async () => {
    setErrorMessage({
      isError: false,
      message: '',
    });

    setBlockCardConfirmationDialog(false);
    setIsBlockUnblockCard(false);
    setUnblockCardConfirmationDialog(false);

    await props.cardActions.cardClearErrorState();
  };

  return (
    <div>
      <LoadingBackdrop status={cardDetailLoading} />
      <SuccessMessageDialog
        message={successMessage}
        openSuccess={openSuccess}
        bindHandleClose={handleCloseSuccess}
        className={classes.roundedButton}
      />
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      <ConfirmationStatus
        openConfirmation={blockCardConfirmationDialog}
        bindHandleCloseConfirmationStatus={handleConfirmationBlockCard}
        message={customization.confirmationMessageDialog.questionBlockCardConfirmation}
      />
      <ConfirmationStatus
        openConfirmation={unblockCardConfirmationDialog}
        bindHandleCloseConfirmationStatus={handleUnblockConfirmationCard}
        message={customization.confirmationMessageDialog.questionUnblockCardConfirmation}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={openCardDetailDialog}
        aria-labelledby="form-dialog-title"
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              {loading !== apiRequestStatus.PENDING ? (
                <Typography variant="h6" className={classes.addLocationDialogStyle}>
                  {customization.cardDetails.title} ({cardDetail.cardHolderName}/
                  {cardDetail.cardNumber})
                </Typography>
              ) : (
                <Typography variant="h6" className={classes.addLocationDialogStyle}>
                  {customization.cardDetails.title}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogContent className={classes.dialogContent}>
          {loading !== apiRequestStatus.PENDING ? (
            <Grid container>
              <Grid
                id="section-1"
                container
                spacing={2}
                className={classes.sectionOneGrid}
              >
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.cardGrid}>
                  <div className={classes.cardContainer}>
                    <CardHeroCard cardData={cardDetail} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography className={classes.cardInformationLabel}>
                    {customization.cardDetails.cardHolderLabel}
                    <Link
                      className={classes.cardHolderLink}
                      onClick={handleRedirectToViewCards}
                    >
                      {' '}
                      {cardDetail.cardHolderName}({cardDetail.userType})
                    </Link>
                  </Typography>
                  <Typography className={classes.cardInformationLabel}>
                    {customization.cardDetails.lastTransactionDateLabel}{' '}
                    <b>
                      {cardDetail.lastTransactionDate
                        ? moment(cardDetail.lastTransactionDate, 'DD/MM/YYYY hh:mm a')
                            .utc()
                            .tz(props.timezone)
                            .format('DD/MM/YYYY - hh:mm a')
                        : 'N/A'}
                    </b>
                  </Typography>
                  <Typography className={classes.lastTransactionLabel}>
                    {customization.cardDetails.lastTransactionMerchantLabel}{' '}
                    <b>{cardDetail.lastTransactionMerchantName}</b>
                  </Typography>
                  <Typography className={classes.cardInformationLabel}>
                    {customization.cardDetails.lastTransactionAmountLabel}{' '}
                    <b>{cardDetail.lastTransactionAmount}</b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                id="section-2"
                container
                spacing={2}
                className={classes.sectionTwoGrid}
              >
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {cardDetail.cardStatus === cardStatus.FRAUD_DETECTED && (
                    <div className={classes.messageContainer}>
                      <Typography className={classes.leftPanelMessage}>
                        {customization.cardDetails.leftPanelMessage.fraud}
                      </Typography>
                    </div>
                  )}
                  {cardDetail.cardStatus === cardStatus.INACTIVE && (
                    <div className={classes.messageContainer}>
                      <Typography className={classes.leftPanelMessage}>
                        {customization.cardDetails.leftPanelMessage.closed}
                      </Typography>
                    </div>
                  )}

                  {(cardDetail.cardStatus === cardStatus.ACTIVE ||
                    cardDetail.cardStatus === cardStatus.BLOCKED ||
                    cardDetail.cardStatus === cardStatus.EXCEEDED_INCORRECT_PIN) && (
                    <div className={classes.buttonGroupsContainer}>
                      {cardDetail.cardStatus === cardStatus.EXCEEDED_INCORRECT_PIN && (
                        <div id="exceeded-message" className={classes.buttonContainer}>
                          <Typography variant="h6" className={classes.exceededPinMessage}>
                            {customization.cardDetails.leftPanelMessage.exceededPin}
                          </Typography>
                        </div>
                      )}
                      <div id="button-unblock-block" className={classes.buttonContainer}>
                        {cardDetail.cardStatus === cardStatus.BLOCKED && (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={cardDetailLoading === apiRequestStatus.PENDING}
                            className={classes.buttonLostStolenCard}
                            onClick={handleUnblockCard}
                          >
                            {customization.cardDetails.unblockButtonLabel}
                          </Button>
                        )}
                        {(cardDetail.cardStatus === cardStatus.ACTIVE ||
                          cardDetail.cardStatus ===
                            cardStatus.EXCEEDED_INCORRECT_PIN) && (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={cardDetailLoading === apiRequestStatus.PENDING}
                            className={classes.buttonLostStolenCard}
                            onClick={handleBlockCard}
                          >
                            {customization.cardDetails.blockButtonLabel}
                          </Button>
                        )}
                      </div>
                      <div id="button-replace-card" className={classes.buttonContainer}>
                        <Button
                          variant="contained"
                          disabled={cardDetailLoading === apiRequestStatus.PENDING}
                          className={classes.buttonLostStolenCard}
                          onClick={() => {
                            setOpenUpdateAddress(true);
                          }}
                          color="primary"
                        >
                          {customization.cardDetails.replaceButtonLabel}
                        </Button>
                      </div>
                      <div
                        id="button-lost-stolen-card"
                        className={classes.buttonContainer}
                      >
                        <Button
                          variant="contained"
                          disabled={cardDetailLoading === apiRequestStatus.PENDING}
                          color="primary"
                          className={classes.buttonLostStolenCard}
                          onClick={handleReportCardStolen}
                        >
                          {customization.cardDetails.reportCardStolenButtonLabel}
                        </Button>
                      </div>
                      <div id="button-cancel-card" className={classes.buttonContainer}>
                        <Button
                          variant="contained"
                          disabled={cardDetailLoading === apiRequestStatus.PENDING}
                          color="primary"
                          className={classes.buttonCancelCard}
                          onClick={handleCancelCard}
                        >
                          {customization.cardDetails.cancelCardButtonLabel}
                        </Button>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <form className={classes.formContainer}>
                    {!cardDetail.accountId && (
                      <div className={classes.limitMessageNullContainer}>
                        <Typography className={classes.limitMessageNullTitle}>
                          {customization.cardDetails.limitMessageNullTitle}
                        </Typography>
                        <Typography className={classes.limitMessageNullBody}>
                          {customization.cardDetails.limitMessageNullBody}
                        </Typography>
                      </div>
                    )}
                    {cardDetail.accountId && (
                      <FormControl className={classes.formControl}>
                        <Typography className={classes.labelForm}>
                          {customization.cardDetails.cashWithdrawalLimitFormLabel}
                        </Typography>
                        <TextField
                          name="cashWithdrawalLimit"
                          disabled={
                            cardDetail.cardStatus === cardStatus.FRAUD_DETECTED ||
                            cardDetail.cardStatus === cardStatus.INACTIVE
                          }
                          className={classes.inputPasswordRoot}
                          id="input-cash-withdrawal"
                          aria-describedby="my-helper-text"
                          value={cashWithdrawalLimit.value}
                          onChange={handleInput}
                          onBlur={parsingValue}
                          onKeyDown={handleDelete}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornmentStyle}
                              >
                                {cardDetail.currencySymbol}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    )}
                    {cardDetail.accountId && (
                      <FormControl className={classes.formControl}>
                        <Typography className={classes.labelForm}>
                          {customization.cardDetails.transactionLimitFormLabel}
                        </Typography>
                        <TextField
                          name="transactionLimit"
                          disabled={
                            cardDetail.cardStatus === cardStatus.FRAUD_DETECTED ||
                            cardDetail.cardStatus === cardStatus.INACTIVE
                          }
                          className={classes.inputPasswordRoot}
                          id="input-transaction-limit"
                          aria-describedby="my-helper-text"
                          value={transactionLimit.value}
                          onChange={handleInput}
                          onBlur={parsingValue}
                          onKeyDown={handleDelete}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornmentStyle}
                              >
                                {cardDetail.currencySymbol}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    )}
                    {cardDetail.accountId && (
                      <FormControl className={classes.formControl}>
                        <Typography className={classes.labelForm}>
                          {customization.cardDetails.dailyLimitFormLabel}
                        </Typography>
                        <TextField
                          name="dailySpendLimit"
                          disabled={
                            cardDetail.cardStatus === cardStatus.FRAUD_DETECTED ||
                            cardDetail.cardStatus === cardStatus.INACTIVE
                          }
                          className={classes.inputPasswordRoot}
                          id="input-daily-limit"
                          aria-describedby="my-helper-text"
                          value={dailySpendLimit.value}
                          onChange={handleInput}
                          onBlur={parsingValue}
                          onKeyDown={handleDelete}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornmentStyle}
                              >
                                {cardDetail.currencySymbol}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    )}
                  </form>
                </Grid>
              </Grid>
              <Grid
                id="section-3"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                className={classes.bottomButtonContainer}
              >
                <Grid item xs={4} sm={3} md={3}>
                  <Button
                    fullWidth
                    disabled={
                      loading === apiRequestStatus.PENDING ||
                      !cardDetail.accountId ||
                      cardDetail.cardStatus === cardStatus.FRAUD_DETECTED ||
                      cardDetail.cardStatus === cardStatus.INACTIVE
                    }
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={handleFormSubmit}
                  >
                    {customization.cardDetails.saveChangesLabel}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={2} md={2}>
                  <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.buttonCancel}
                    onClick={handleClose}
                  >
                    {customization.userRootForm.cancelButton}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <CardDetailLoading gridSize={12} circularNumber={3} />
          )}
        </DialogContent>
      </Dialog>
      <UpdateAddressDialog
        open={openUpdateAddress}
        userId={cardDetail.userId}
        handleReplaceCard={handleReplaceCard}
        onClose={() => {
          setOpenUpdateAddress(false);
        }}
      />
    </div>
  );
}

CardDetailDialog.propTypes = {
  classes: PropTypes.object,
  postUserData: PropTypes.func,
  openCardDetailDialog: PropTypes.bool,
  card: PropTypes.object,
  bindCloseCardDetail: PropTypes.func,
  loading: PropTypes.string,
  userAction: PropTypes.object,
  cardActions: PropTypes.object,
  error: PropTypes.object,
  token: PropTypes.string,
  timezone: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    card: state.card.card,
    loading: state.card.loading,
    error: state.card.error,
    timezone: state.auth.timezone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cardActions: bindActionCreators(cardActions, dispatch),
    userAction: bindActionCreators(userActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withStyles(addLocationDialogStyle), withConnect)(CardDetailDialog);
